import { memo } from 'react'
import { ConnectorPowerType } from 'src/_shared/enums/filter'
import { classNames } from 'src/_shared/utils/elements'

interface ConnectorRatingBoxProps {
	powerType: ConnectorPowerType
	power: string
	availableCount: number
	totalCount: number
	className?: string
}

const ConnectorRatingBox = ({
	powerType,
	power,
	availableCount,
	totalCount,
	className
}: ConnectorRatingBoxProps): JSX.Element => {
	const isPowerTypeAc = powerType === ConnectorPowerType.Ac
	return (
		<div className={classNames('flex h-7 flex-row items-center', className)}>
			<div
				className={classNames(
					'flex h-full  w-2/3 items-center space-x-1 rounded-l-md border px-1.5',
					isPowerTypeAc ? 'border-primary-200 bg-primary-200' : 'border-alert-200 bg-alert-200'
				)}
			>
				<p className="body-1-semibold text-typography-primary">{powerType}</p>
				<p className="body-1-normal text-typography-primary">{power}kW</p>
			</div>
			<div className="flex h-full w-1/3 items-center justify-center rounded-r-md border border-l-0 border-divider-primary px-1.5">
				<p
					className={classNames(
						'body-1-semibold',
						availableCount === 0 ? 'text-error-300' : 'text-success-400'
					)}
				>
					{availableCount}
				</p>
				<p className="body-1-normal text-typography-primary">/{totalCount}</p>
			</div>
		</div>
	)
}

const MemoisedConnectorRatingBox = memo(ConnectorRatingBox)

export default MemoisedConnectorRatingBox
