import { memo, MouseEvent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath, useRouterLocation } from 'src/App/router/hooks'
import { classNames } from 'src/_shared/utils/elements'

export interface NavigationItemProps {
	title: string
	pathname:
		| ScreenRoutePath.Map
		| ScreenRoutePath.Locations
		| ScreenRoutePath.Favourites
		| ScreenRoutePath.Account
	defaultIcon: JSX.Element
	selectedIcon: JSX.Element
	onLinkClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

const NavigationItem = ({
	title,
	pathname,
	defaultIcon,
	selectedIcon,
	onLinkClick: handleLinkClick
}: NavigationItemProps): JSX.Element => {
	const location = useRouterLocation()

	const isSelected = useMemo((): boolean => {
		return location.pathname.includes(pathname)
	}, [location.pathname, pathname])

	return (
		<Link
			className="flex cursor-pointer flex-col items-center justify-end no-underline"
			to={pathname}
			onClick={handleLinkClick}
		>
			<div className="flex flex-col items-center justify-center gap-0.5">
				{isSelected ? selectedIcon : defaultIcon}
				<span
					className={classNames(
						'caption-3-normal',
						isSelected ? 'text-typography-primary' : 'text-typography-tertiary/75'
					)}
				>
					{title}
				</span>
			</div>
		</Link>
	)
}

const MemoisedNavigationItem = memo(NavigationItem)

export default MemoisedNavigationItem
