export enum FilterType {
	All = 'ALL',
	Cpo = 'CPO',
	Favourites = 'FAVOURITES',
	Ac = 'AC',
	Dc = 'DC'
}

export enum SubscriptionStatus {
	Subscribed = 'SUBSCRIBED',
	NotSubscribed = 'NOT_SUBSCRIBED'
}

export enum LocationFilterModalType {
	All = 'ALL',
	Cpo = 'CPO'
}

export enum ConnectorPowerType {
	Ac = 'AC',
	Dc = 'DC'
}
