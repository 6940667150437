import { MAP_DEFAULT_CENTER_LAT, MAP_DEFAULT_CENTER_LNG } from 'src/_shared/constants/env'

export const CURRENT_LOCATION_ZOOM = 15

export const DEFAULT_CENTER: google.maps.LatLngLiteral = {
	lat: MAP_DEFAULT_CENTER_LAT,
	lng: MAP_DEFAULT_CENTER_LNG
}

export const MAX_ZOOM = 18

export const MIN_ZOOM = 8
