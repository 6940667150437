import { DetailedHTMLProps, HTMLAttributes, memo, MouseEvent, useCallback } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import BookmarkFilledIcon from 'src/_shared/components/_icons/BookmarkFilledIcon'
import BookmarkIcon from 'src/_shared/components/_icons/BookmarkIcon'
import BottomScanQrIcon from 'src/_shared/components/_icons/BottomBarScanQrIcon'
import ListIcon from 'src/_shared/components/_icons/ListIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import { USER_ZOOM_COORDINATES_KEY } from 'src/_shared/constants/storage'
import { UserZoomCoordinates } from 'src/_shared/hooks/useUserZoomCoordinates'
import { classNames } from 'src/_shared/utils/elements'

import AccountFilledIcon from '../../_icons/AccountFilledIcon'
import AccountIcon from '../../_icons/AccountIcon'
import LocationPinFilledIcon from '../../_icons/LocationPinFilledIcon'
import LocationPinIcon from '../../_icons/LocationPinIcon'
import NavigationItem from './NavigationItem'

export type BottomBarProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'children' | 'ref'
>

const BottomBar = ({ className, style, ...props }: BottomBarProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const handleMapLinkClick = useCallback(
		(event: MouseEvent<HTMLAnchorElement>): void => {
			event.preventDefault() // Handle the redirection manually
			try {
				const storedUserZoomCoordinates = sessionStorage.getItem(USER_ZOOM_COORDINATES_KEY)
				if (storedUserZoomCoordinates) {
					const parsedUserZoomCoordinates: unknown = JSON.parse(storedUserZoomCoordinates)
					if (parsedUserZoomCoordinates) {
						const { coordinates, zoom = DEFAULT_ZOOM } =
							parsedUserZoomCoordinates as UserZoomCoordinates
						const { lat = 0, lng = 0 } = coordinates ?? {}
						navigate({
							pathname: ScreenRoutePath.Map,
							search: `lat=${lat}&lng=${lng}&zoom=${zoom}`
						})
					}
				} else {
					navigate(ScreenRoutePath.Map)
				}
			} catch (error) {
				console.error('[BottomBar]', error)
			}
		},
		[navigate]
	)

	return (
		<div
			className={classNames(
				'sticky bottom-0 z-50 flex justify-around bg-white px-2 pt-3 shadow-[0_0_20px_7px_rgba(0,0,0,0.04)] 2xs:px-5',
				className
			)}
			style={{
				...style,
				paddingBottom: style?.paddingBottom ?? 'calc(12px + env(safe-area-inset-bottom))'
			}}
			{...props}
		>
			<div className="flex flex-1 justify-around">
				<NavigationItem
					title="Map"
					pathname={ScreenRoutePath.Map}
					defaultIcon={<LocationPinIcon className="h-6 w-6 text-typography-tertiary/75" />}
					selectedIcon={<LocationPinFilledIcon className="h-6 w-6 text-primary-800" />}
					onLinkClick={handleMapLinkClick}
				/>
				<NavigationItem
					title="List"
					pathname={ScreenRoutePath.Locations}
					defaultIcon={<ListIcon className="h-6 w-6 text-typography-tertiary/75" />}
					selectedIcon={<ListIcon className="h-6 w-6 text-primary-800" />}
				/>
			</div>
			<div className="flex w-16 justify-center">
				<Button
					className="h-16 w-16 rounded-full text-button-primary-content shadow-[0_0_20px_7px_rgba(0,0,0,0.04)]"
					linkProps={{
						className: 'absolute bottom-9',
						to: ScreenRoutePath.CheckIn
					}}
				>
					<BottomScanQrIcon className="mt-1 h-16 w-16" />
				</Button>
			</div>
			<div className="flex flex-1 justify-around">
				<NavigationItem
					title="Favourites"
					pathname={ScreenRoutePath.Favourites}
					defaultIcon={<BookmarkIcon className="h-6 w-6 text-typography-tertiary/75" />}
					selectedIcon={<BookmarkFilledIcon className="h-6 w-6 text-primary-800" />}
				/>
				<NavigationItem
					title="Account"
					pathname={ScreenRoutePath.Account}
					defaultIcon={<AccountIcon className="h-6 w-6 text-typography-tertiary/75" />}
					selectedIcon={<AccountFilledIcon className="h-6 w-6 text-primary-800" />}
				/>
			</div>
		</div>
	)
}

const MemoisedBottomBar = memo(BottomBar)

export default MemoisedBottomBar
