/**
 * The language/locale of the application is stored at this key inside of `localStorage`.
 */
export const LANGUAGE_KEY = 'lang'

/**
 * The Session ID returned when starting a transient charging session is stored at this key.
 * This primarily acts as a flag to determine when to enable `useSessionTransientQuery`.
 */
export const TRANSIENT_SESSION_ID_KEY = 'transientSessionId'

/**
 * The last known coordinates and zoom of the Map view.
 */
export const USER_ZOOM_COORDINATES_KEY = 'userZoomCoordinates'
