import { QueryStatus } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ChargerScreenRouteParams } from 'src/screens/ChargerScreen/types'

import { OmniSessionStatus } from '../enums/omni'
import { useLocationEvseConnectorCurrentSessionQuery } from '../queries/locations'
import { useUserSessionLatestQuery } from '../queries/user'
import { OmniSession } from '../types/omni'
import { useAuthContext } from './useAuthContext'

type ChargerDetails = ChargerScreenRouteParams

interface LiveSessionData {
	chargerDetails: ChargerDetails | null
	session: OmniSession | null
	/**
	 * Overall status of the hook depending on `isAuthenticated` and the query statuses.
	 * If `status` is `success`, then `chargerDetails` and `session` is ready for use.
	 */
	status: QueryStatus
}

// 60 Seconds
const DEFAULT_REFETCH_INTERVAL = 60000

/**
 * If the user currently has an active charging session, return the session and the involved charger's details.
 * @returns {LiveSessionData} The active charging session data if there is an active charging session.
 */
const useLiveSession = (): LiveSessionData => {
	const { isAuthenticated } = useAuthContext()

	const { data: userSessionLatest = null, status: userSessionLatestQuery } =
		useUserSessionLatestQuery(
			{
				isRealTime: true
			},
			{
				refetchInterval: DEFAULT_REFETCH_INTERVAL,
				refetchOnMount: 'always',
				refetchIntervalInBackground: true
			}
		)

	const chargerDetails = useMemo((): ChargerDetails => {
		const {
			entity_code: cpoEntityCode = '',
			location_uid: locationUid = '',
			evse_uid: evseUid = '',
			connector_uid: connectorUid = ''
		} = userSessionLatest ?? {}

		return {
			cpoEntityCode,
			locationUid,
			evseUid,
			connectorUid
		}
	}, [userSessionLatest])

	const { data: chargerCurrentSession = null, status: locationEvseConnectorCurrentSessionStatus } =
		useLocationEvseConnectorCurrentSessionQuery(chargerDetails, {
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			refetchOnMount: 'always',
			refetchIntervalInBackground: true,
			enabled: !!userSessionLatest && isAuthenticated
		})

	const status = useMemo((): QueryStatus => {
		if (isAuthenticated) {
			const queryStatuses: QueryStatus[] = [
				userSessionLatestQuery,
				locationEvseConnectorCurrentSessionStatus
			]
			if (queryStatuses.some((queryStatus): boolean => queryStatus === 'error')) {
				return 'error'
			} else if (queryStatuses.some((queryStatus): boolean => queryStatus === 'pending')) {
				return 'pending'
			}
		}
		return 'success'
	}, [isAuthenticated, userSessionLatestQuery, locationEvseConnectorCurrentSessionStatus])

	const liveSessionData = useMemo((): Pick<LiveSessionData, 'chargerDetails' | 'session'> => {
		if (!!userSessionLatest && !!chargerCurrentSession) {
			const isUserLatestAndChargerCurrentSessionSame =
				userSessionLatest._id === chargerCurrentSession._id

			const isUserSessionLatestActive = userSessionLatest.status === OmniSessionStatus.Active

			if (isUserLatestAndChargerCurrentSessionSame && isUserSessionLatestActive) {
				return {
					chargerDetails,
					session: userSessionLatest
				}
			}
		}
		return {
			chargerDetails: null,
			session: null
		}
	}, [chargerCurrentSession, chargerDetails, userSessionLatest])

	return {
		...liveSessionData,
		status
	}
}

export default useLiveSession
