import { OmniCountryCode } from 'src/_shared/enums/omni'

const SG_PHONE_NUMBER_REGEX = /^(\+65|65)(\d{8})$/

const MY_PHONE_NUMBER_REGEX = /^(\+60|60)(\d{9,10})$/

const TH_PHONE_NUMBER_REGEX = /^(\+66|66)(\d{8,9})$/

/**
 * Determines the user's country based on the provided country code or phone number.
 * The function follows these steps:
 * 1. If `currentCountryCode` is provided, it returns `currentCountryCode`.
 * 2. If the phone number matches the Singapore phone number regex, it returns `OmniCountryCode.Singapore`.
 * 3. If the phone number matches the Malaysia phone number regex, it returns `OmniCountryCode.Malaysia`.
 * 4. If the phone number matches the Thailand phone number regex, it returns `OmniCountryCode.Thailand`.
 * 5. If none of the above conditions are met, it defaults to returning `OmniCountryCode.Singapore`.
 * @param {OmniCountryCode | undefined} currentCountryCode - The current country code of the user, if available.
 * @param {string} phoneNumber - The user's phone number.
 * @returns {OmniCountryCode} The determined country code based on the provided information.
 */
export const getUserCountry = (
	currentCountryCode?: OmniCountryCode,
	phoneNumber = ''
): OmniCountryCode => {
	switch (true) {
		case !!currentCountryCode:
			return currentCountryCode
		case SG_PHONE_NUMBER_REGEX.test(phoneNumber):
			return OmniCountryCode.Singapore
		case MY_PHONE_NUMBER_REGEX.test(phoneNumber):
			return OmniCountryCode.Malaysia
		case TH_PHONE_NUMBER_REGEX.test(phoneNumber):
			return OmniCountryCode.Thailand
		default:
			return OmniCountryCode.Singapore
	}
}
