import { ConnectorPowerType } from 'src/_shared/enums/filter'
import { OmniConnectorPowerType, OmniConnectorStatus } from 'src/_shared/enums/omni'
import {
	ConnectorWithDetails,
	GroupConnectors,
	GroupedConnectors
} from 'src/_shared/types/charging'
import { OmniConnector, OmniEvse, OmniTariff } from 'src/_shared/types/omni'
import { filterEvsesByPowerType } from 'src/_shared/utils/filter'

import { formatCurrencyValue } from './string'

/**
 * Determines the power type of a given connector.
 *
 * @param {OmniConnector} connector - The connector object of type `OmniConnector`.
 * @returns {ConnectorPowerType} The power type of the connector, either `ConnectorPowerType.Dc` or `ConnectorPowerType.Ac`.
 */
export const getConnectorPowerType = (connector: OmniConnector): ConnectorPowerType => {
	return connector.power_type === OmniConnectorPowerType.Dc
		? ConnectorPowerType.Dc
		: ConnectorPowerType.Ac
}

/**
 * Checks if there are any available connectors in the provided list.
 *
 * @param {OmniConnector[]} connectors - The list of connectors to check.
 * @returns {boolean} - Returns `true` if there is at least one available connector, otherwise `false`.
 */
export const hasAvailableConnectors = (connectors: OmniConnector[]): boolean => {
	return connectors.some((connector) => isConnectorAvailable(connector))
}

/**
 * Determines if the target connector is available to the user by checking against
 * its current `status` and other relevant flags.
 * @param {OmniConnector} connector The target connector.
 * @returns {boolean} If `true`, then the target connector is available to the user.
 */
export const isConnectorAvailable = (connector: OmniConnector): boolean => {
	const isConnectorNotInUse =
		!!connector.status &&
		[OmniConnectorStatus.Available, OmniConnectorStatus.Preparing].includes(connector.status)

	const isConnectorEnabled = connector.is_enabled !== false

	const isConnectorOnline = connector.online !== false

	return isConnectorNotInUse && isConnectorEnabled && isConnectorOnline
}

/**
 * Calculates the total and available counts of AC and DC connectors.
 * @param {OmniEvse[]} [evses=[]] - An array of EVSEs.
 * @returns {{ totalAcCount: number, totalDcCount: number, availableAcCount: number, availableDcCount: number }} An object containing the counts of total and available AC and DC connectors.
 */
export const getConnectorCounts = (
	evses: OmniEvse[] = []
): {
	totalAcCount: number
	totalDcCount: number
	availableAcCount: number
	availableDcCount: number
} => {
	let totalAcCount = 0
	let totalDcCount = 0
	let availableAcCount = 0
	let availableDcCount = 0

	if (evses.length > 0) {
		for (const evse of evses) {
			if (!evse.connectors) continue

			for (const connector of evse.connectors) {
				const powerType = getConnectorPowerType(connector)
				if (powerType === ConnectorPowerType.Ac) {
					totalAcCount++
					if (isConnectorAvailable(connector)) {
						availableAcCount++
					}
				} else {
					totalDcCount++
					if (isConnectorAvailable(connector)) {
						availableDcCount++
					}
				}
			}
		}
	}

	return { totalAcCount, totalDcCount, availableAcCount, availableDcCount }
}

/**
 * Calculates the number of available connectors and the total number of connectors.
 *
 * @param {ConnectorWithDetails[]} [connectors=[]] - An array of connectors with details.
 * @returns {{ availableCount: number, totalCount: number }} An object containing the count of available connectors and the total count of connectors.
 */
export const getAvailableConnectorsCount = (
	connectors: ConnectorWithDetails[] = []
): { availableCount: number; totalCount: number } => {
	let availableCount = 0
	let totalCount = 0

	for (const connector of connectors) {
		totalCount++

		if (isConnectorAvailable(connector.connector)) {
			availableCount++
		}
	}

	return { availableCount, totalCount }
}

/**
 * Retrieves the power rating of a connector in kilowatts (kW).
 * @param {OmniConnector} connector The target connector.
 * @returns {string} The power rating of the connector in kW as a string.
 */
export const getConnectorPowerRating = (connector: OmniConnector): string => {
	if (!connector.max_electric_power) {
		return '0'
	}
	return (connector.max_electric_power / 1000).toString()
}

/**
 * Retrieves the highest tariff from a list of EVSEs based on the specified comparison key and tariff key.
 *
 * @param {'price_per_kwh' | `price_per_min`} comparisonKey - The key to compare tariffs ('price_per_kwh' or 'price_per_min').
 * @param {'active_tariff' | 'original_tariff'} tariffKey - The key to access the tariff ('active_tariff' or 'original_tariff').
 * @param {OmniEvse[]} evses - An array of OmniEvse objects.
 * @param {ConnectorPowerType | null} powerTypeFilter - A filter for the connector power type. Can be null.
 * @returns {OmniTariff | null} The highest OmniTariff object or null if no tariff is found.
 */
export const getHighestTariff = (
	comparisonKey: 'price_per_kwh' | 'price_per_min',
	tariffKey: 'active_tariff' | 'original_tariff',
	evses: OmniEvse[],
	powerTypeFilter: ConnectorPowerType | null
): OmniTariff | null => {
	let highestTariff: OmniTariff | null = null
	const filteredEvses = filterEvsesByPowerType(evses, powerTypeFilter)
	for (const evse of filteredEvses) {
		if (!evse.connectors) continue

		for (const connector of evse.connectors) {
			const pricePerKwh = connector[tariffKey]?.[comparisonKey]?.incl_vat ?? 0

			if (!highestTariff || (highestTariff[comparisonKey]?.incl_vat ?? 0) < pricePerKwh) {
				highestTariff = connector[tariffKey] ?? null
			}
		}
	}
	return highestTariff
}

/**
 * Retrieves the highest tariff price value from a list of EVSEs (Electric Vehicle Supply Equipment).
 *
 * @param {OmniEvse[]} evses - An array of OmniEvse objects. Defaults to an empty array.
 * @param {ConnectorPowerType | null} powerTypeFilter - A filter for the connector power type. Can be null.
 * @param {boolean} isAuthenticated - A boolean indicating if the user is authenticated.
 * @returns {string | null} The highest tariff price value as a formatted string, or null if no tariff is found.
 *
 * The function prioritizes the `price_per_kwh` value over `price_per_min`. If all connectors lack a `price_per_kwh` value,
 * it falls back to using `price_per_min`. For authenticated users, the active tariff values are used, which may include
 * price modifiers. The function returns the price formatted as a string with the appropriate currency and unit (either
 * per kWh or per minute).
 */
export const getHighestTariffPriceValue = (
	evses: OmniEvse[] = [],
	powerTypeFilter: ConnectorPowerType | null,
	isAuthenticated: boolean
): string | null => {
	// Prioritise `price_per_kwh`; fallback to `price_per_min` otherwise.
	const comparisonKey = evses.every((evse) =>
		evse.connectors?.every((connector) => !connector.active_tariff?.price_per_kwh?.incl_vat)
	)
		? 'price_per_min'
		: 'price_per_kwh'

	// Logged-in users should see the active tariff values instead (which has price modifiers).
	const tariffKey = isAuthenticated ? 'active_tariff' : 'original_tariff'

	const tariff = getHighestTariff(comparisonKey, tariffKey, evses, powerTypeFilter)

	if (tariff === null) {
		return null
	}

	const pricePerKwh = tariff.price_per_kwh?.incl_vat ?? 0

	const pricePerMin = tariff.price_per_min?.incl_vat ?? 0

	// Prioritise display of `price_per_kwh` over `price_per_min`, even when both are zero.
	if (pricePerKwh > 0 || pricePerMin === 0) {
		return `${formatCurrencyValue(pricePerKwh, tariff.currency)}/kWh`
	}
	return `${formatCurrencyValue(pricePerMin, tariff.currency)}/min`
}

/**
 * Groups connectors by their power rating and power type.
 * @param {OmniEvse[]} evses An array of connectors to be grouped.
 * @returns {GroupedConnectors} A map where the keys are a combination of power rating and power type,
 * and the values are objects containing the power rating, power type, and an array of connectors.
 */
export const groupConnectorsByPower = (evses: OmniEvse[]): GroupedConnectors => {
	const groupedConnectors: Record<string, GroupConnectors> = {}
	for (const evse of evses) {
		evse.connectors?.forEach((connector): void => {
			const power = getConnectorPowerRating(connector)
			const powerType = getConnectorPowerType(connector)
			const powerKey = `${power}_${powerType}`
			if (!(powerKey in groupedConnectors)) {
				groupedConnectors[powerKey] = {
					power,
					powerType,
					connectors: []
				}
			}
			groupedConnectors[powerKey].connectors.push({
				connector,
				evse,
				power
			})
		})
	}
	return groupedConnectors
}

/**
 * Sorts EVSEs by their power and groups them by power rating and power type.
 * @param {OmniEvse[]} evses An array of EVSEs to be sorted and grouped.
 * @returns {GroupedConnectors} A map where the keys are a combination of power rating and power type,
 * and the values are objects containing the power rating, power type, and an array of connectors.
 */
export const sortEvsesByPower = (evses: OmniEvse[] = []): GroupedConnectors => {
	const sortedEvses = evses
		// FUTURE TODO: BE should sort the connectors returned.
		// Note: Sort connectors by `connector_id` first. Currently,
		// there is no field we can use to sort connectors by their
		// "on-site/physical" order.
		.sort((a, b): number => {
			const propertyA = a.physical_reference ?? a.evse_id
			const propertyB = b.physical_reference ?? b.evse_id
			if (!propertyA || !propertyB) {
				return 0
			}
			return propertyA > propertyB ? 1 : propertyB > propertyA ? -1 : 0
		})

	const grouped = groupConnectorsByPower(sortedEvses)

	const sortedEntries = Array.from(Object.entries(grouped)).sort(
		([, valueA], [, valueB]): number => {
			const isAcA = valueA.powerType === ConnectorPowerType.Ac
			const isAcB = valueB.powerType === ConnectorPowerType.Ac
			if (isAcA && !isAcB) {
				return -1
			}
			if (!isAcA && isAcB) {
				return 1
			}
			if (isAcA && isAcB) {
				return Number(valueA.power) - Number(valueB.power)
			}
			return Number(valueA.power) - Number(valueB.power)
		}
	)

	return Object.fromEntries(sortedEntries)
}
