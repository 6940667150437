import { Fragment, memo, ReactNode, useMemo } from 'react'
import ChargerTag from 'src/_shared/components/ChargerTag'
import ConnectorRatingBoxes from 'src/_shared/components/ConnectorRatingBoxes'
import LocationDetailsBox from 'src/_shared/components/LocationDetailsBox'
import PriceModifierTag from 'src/_shared/components/PriceModifierTag'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useIsUserSubscribed } from 'src/_shared/hooks/useIsUserSubscribed'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni/location'
import { formatDistanceDisplay } from 'src/_shared/utils/format'

interface LocationListItemProps {
	locationNearby: OmniLocationNearby
	/**
	 * Displayed to the left of the `LocationListItem` element.
	 */
	startAdornment?: ReactNode
	onLocationListItemClick?: (location: OmniLocation) => void
}

const LocationListItem = ({
	locationNearby,
	startAdornment,
	onLocationListItemClick: handleLocationListItemClick
}: LocationListItemProps) => {
	const location = locationNearby.location

	const distanceMetres = locationNearby.distance_meters

	const { isAuthenticated } = useAuthContext()

	const evseWithPriceModifier = location?.evses?.find((evse) =>
		// As long as there's at least 1 connector with a price modifier,
		// we display that found price modifier. This also assumes that
		// the same price modifier is applied to all connectors, although
		// it might be possible that it will not be so in the future.
		evse.connectors?.some(
			(connector) => (connector.active_tariff?.price_modifiers ?? []).length > 0
		)
	)

	const activeTariff = evseWithPriceModifier?.connectors?.[0]?.active_tariff

	const priceModifier = activeTariff?.price_modifiers?.[0]

	const isUserSubscribed = useIsUserSubscribed(location?.entity_code ?? '')

	const formattedDistance = useMemo((): string | null => {
		if (distanceMetres) {
			return formatDistanceDisplay(distanceMetres)
		}
		return null
	}, [distanceMetres])

	return (
		<Fragment key={location?.uid}>
			<button
				className="flex w-full items-center justify-between space-x-5 px-4 py-5"
				onClick={(): void => {
					if (location) {
						handleLocationListItemClick?.(location)
					}
				}}
			>
				<div className="flex flex-row items-center">
					{startAdornment && startAdornment}
					{/* Information Section */}
					<div>
						{/* Logo */}
						{location?.operator?.logo && (
							<img className="mb-2 h-7 max-w-20 object-contain" src={location.operator.logo} />
						)}
						{/* Location Name */}
						<p className="body-2-bold mb-0.5 w-full text-wrap text-left text-typography-primary">
							{location?.name}
						</p>
						{/* Location Address */}
						<p className="caption-3-normal mb-2 text-left text-typography-secondary">
							{formattedDistance && (
								<>
									{formattedDistance}
									{' | '}
								</>
							)}
							{location?.address ?? '-'}
						</p>
						{/* Location Details Box to display highest AC/DC tariff and 24-hours badge */}
						{location && (
							<LocationDetailsBox
								location={location}
								className="flex flex-row flex-wrap justify-start gap-2"
							/>
						)}
						{/* Price Modifier and Subscription Tag */}
						<div className="mt-2 flex justify-start">
							{priceModifier && isAuthenticated ? (
								<PriceModifierTag
									key={priceModifier.uid}
									currencyCode={activeTariff.currency}
									priceModifierValue={priceModifier.value}
									priceModifierType={priceModifier.type}
								/>
							) : isUserSubscribed && isAuthenticated ? (
								<ChargerTag
									className="truncate border border-orange-400 bg-orange-400 bg-opacity-15 text-orange-400"
									value="Subscribed"
								/>
							) : null}
						</div>
					</div>
				</div>
				{/* Rating Boxes Section */}
				<ConnectorRatingBoxes
					connectorRatingBoxClassName="min-w-28 max-w-fit"
					className="flex min-w-28 max-w-fit flex-col items-end space-y-1"
					evses={location?.evses}
				/>
			</button>
			<div className="mx-4 border-b border-divider-primary" />
		</Fragment>
	)
}

const MemoisedLocationListItem = memo(LocationListItem)

export default MemoisedLocationListItem
