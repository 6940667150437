import { memo, MouseEvent } from 'react'
import BookmarkFilledIcon from 'src/_shared/components/_icons/BookmarkFilledIcon'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import { classNames } from 'src/_shared/utils/elements'

import { GoogleMapMarkerProps } from '../types'

type LocationMarkerProps = {
	hasAvailableConnector: boolean
	isLocationFavourite: boolean
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void
} & GoogleMapMarkerProps

const LocationMarker = ({
	hasAvailableConnector,
	isLocationFavourite,
	onClick: handleClick
}: LocationMarkerProps): JSX.Element => {
	return (
		// Transformation done to reflect the true center of the coordinates (where the triangle is pointing at)
		<div
			// Account for square height in y-translation
			className="absolute -translate-x-1/2 -translate-y-[calc(50%+16px)] transform"
		>
			<button
				className="animate-fade-in flex flex-col items-center drop-shadow-md"
				onClick={handleClick}
			>
				{/* Square */}
				<div
					className={classNames(
						'flex h-8 w-8 items-center justify-center rounded-lg',
						hasAvailableConnector ? 'bg-success-400' : 'bg-error-300'
					)}
				>
					{isLocationFavourite ? (
						<BookmarkFilledIcon className="h-3.5 w-3.5 text-grayscale-100" />
					) : (
						<LightningBoltFilledIcon className="h-4 w-4 text-grayscale-100" />
					)}
				</div>
				{/* Down Triangle */}
				<div
					className={classNames(
						'mt-[-1px] h-0 w-0 border-x-[6px] border-t-[10px] border-x-transparent',

						hasAvailableConnector ? 'border-t-success-400' : 'border-t-error-300'
					)}
				/>
			</button>
		</div>
	)
}

const MemoisedLocationMarker = memo(LocationMarker)

export default MemoisedLocationMarker
