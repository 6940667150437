import ScreenContainer from 'src/_shared/components/ScreenContainer'
import CheckCircleIcon from 'src/_shared/components/_icons/CheckCircleIcon'
import { Language } from 'src/_shared/enums/env'
import useLanguage from 'src/_shared/hooks/useLanguage'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

const DEFAULT_LANGUAGES = [
	{
		label: 'English',
		value: 'en',
		description: 'Singapore'
	}
]

const AccountSelectLanguageScreen = (): JSX.Element => {
	const { language: currentLanguage, setLanguage } = useLanguage()

	const { brandData } = useStrapiContext()

	const languages = brandData?.attributes.configuration?.languages ?? DEFAULT_LANGUAGES

	const handleUpdateSelectLanguage = (language: Language): void => {
		setLanguage(language)
	}

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			topBarProps={{
				centerRender: <h1>Language</h1>
			}}
			hideBottomBar
		>
			<div className="flex flex-col space-y-4">
				{languages.map(({ label, value, description }, index): JSX.Element | null => {
					if (!label || !value || !description) {
						return null
					}
					const isLanguageSelected = currentLanguage === (value as Language)
					return (
						<button
							key={index}
							onClick={() => {
								handleUpdateSelectLanguage(value as Language)
							}}
							className="flex items-center justify-between border-b border-divider-primary pb-4 last:border-none"
						>
							<div className="flex flex-col items-start py-1">
								<p className="body-2-semibold text-typography-primary">{label}</p>
								<p className="body-1-normal text-typography-secondary">{description}</p>
							</div>

							{isLanguageSelected && <CheckCircleIcon className="h-6 text-success-300" />}
						</button>
					)
				})}
			</div>
		</ScreenContainer>
	)
}

export default AccountSelectLanguageScreen
