import { useCallback, useMemo } from 'react'
import {
	Location,
	NavigateOptions,
	Path,
	SetURLSearchParams,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom'

export enum ScreenRoutePath {
	Account = '/account',
	AccountForgotPassword = '/forgot-password',
	AccountHelp = '/account/help',
	AccountLogin = '/login',
	AccountPaymentMethods = '/account/payment-methods',
	AccountPaymentMethodsNew = '/account/payment-methods/new',
	AccountProfile = '/account/profile',
	AccountProfileChangePassword = '/account/profile/change-password',
	AccountProfileEdit = '/account/profile/edit',
	AccountSelectLanaugage = '/account/select-language',
	AccountRegistration = '/register',
	Charger = '/charger',
	CheckIn = '/check-in',
	CheckInManual = '/check-in/manual',
	CheckInLocationScreen = '/check-in/location',
	CheckInQrScanner = '/check-in/qr-scanner',
	CheckInRedirect = '/check-in/redirect',
	History = '/history',
	HistoryFilter = '/history/filter',
	Session = '/session',
	SessionCancel = '/session/cancel',
	/**
	 * Transient Landing Page.
	 */
	Landing = '/landing',
	Root = '/',
	TermsAndConditions = '/terms-and-conditions',
	HistoryDetail = '/history/detail',
	Map = '/map',
	Location = '/location',
	Locations = '/locations',
	Favourites = '/favourites'
}

type ScreenRoutePathDetailed = {
	pathname:
		| ScreenRoutePath
		| ChargerScreenRoutePath
		| CheckInLocationScreenRoutePath
		| HistoryDetailScreenRoutePath
		| SessionScreenRoutePath
		| LocationDetailsScreenRoutePath
} & Partial<Pick<Path, 'hash' | 'search'>>

/**
 * Format: ['/location', locationUid]
 */
export type LocationDetailsScreenRoutePath = [ScreenRoutePath.Location, string]

/**
 * Format: ['/charger', cpoEntityCode, locationUid, evseUid, connectorUid]
 */
export type ChargerScreenRoutePath = [ScreenRoutePath.Charger, string, string, string, string]

/**
 * Format: ['/check-in/location', locationUid]
 */
export type CheckInLocationScreenRoutePath = [ScreenRoutePath.CheckInLocationScreen, string]

/**
 * Format: ['/session', sessionId]
 */
export type SessionScreenRoutePath = [ScreenRoutePath.Session, string]

/**
 * Format: ['/history/detail', sessionId]
 */
export type HistoryDetailScreenRoutePath = [ScreenRoutePath.HistoryDetail, string]

/**
 * Function Overload.
 */
interface RouterNavigateFunction {
	(
		to:
			| ScreenRoutePath
			| ScreenRoutePathDetailed
			| ChargerScreenRoutePath
			| CheckInLocationScreenRoutePath
			| HistoryDetailScreenRoutePath
			| LocationDetailsScreenRoutePath
			| SessionScreenRoutePath,
		options?: NavigateOptions
	): void
	(delta: number): void
}

/**
 * Returns the current location object, which represents the current URL in web browsers.
 * @returns {Location<T>} The current location object.
 * @see https://reactrouter.com/hooks/use-location
 */
export const useRouterLocation = <T = null>(): Location<T> => {
	const location = useLocation() as Location<T>
	return location
}

/**
 * Determines whether the `navigateTo` argument used in useNavigate hook
 * is of type `ScreenRoutePathDetailed` (has either or both 'search' or 'hash' param)
 * @returns {navigateTo is ScreenRoutePathDetailed}
 */
const isDetailedScreenRoutePath = (
	navigateTo:
		| ScreenRoutePath
		| ScreenRoutePathDetailed
		| ChargerScreenRoutePath
		| CheckInLocationScreenRoutePath
		| HistoryDetailScreenRoutePath
		| LocationDetailsScreenRoutePath
		| SessionScreenRoutePath
): navigateTo is ScreenRoutePathDetailed => {
	return typeof navigateTo === 'object' && 'pathname' in navigateTo
}

/**
 * Returns an imperative function for changing the location. Its underlying behaviour is the same as `react-router-dom`,
 * the only difference is that the arguments are much more strictly-typed to account for `ScreenRoutePathKey`.
 * @returns {RouterNavigateFunction} The `navigate` function.
 * @see https://reactrouter.com/hooks/use-navigate
 */
export const useRouterNavigate = (): RouterNavigateFunction => {
	const navigate = useNavigate()

	const routerNavigate: RouterNavigateFunction = useCallback(
		(...args): void => {
			const navigateTo = args[0]
			if (typeof navigateTo === 'number') {
				navigate(navigateTo)
			} else {
				const options = args[1] as NavigateOptions | undefined
				let pathname: string | string[]
				let search: string | undefined
				let hash: string | undefined
				if (isDetailedScreenRoutePath(navigateTo)) {
					pathname = navigateTo.pathname
					search = navigateTo.search
					hash = navigateTo.hash
				} else {
					pathname = navigateTo
				}
				const formattedNavigateTo = Array.isArray(pathname) ? pathname.join('/') : pathname
				navigate(
					{
						pathname: formattedNavigateTo,
						search: search,
						hash: hash
					},
					options
				)
			}
		},
		[navigate]
	)

	return routerNavigate
}

/**
 * Returns the defined set of parameters attached in the `search` string and a function to modify the query string.
 * @returns {[T, SetURLSearchParams]} The parsed query parameters from the `search` string and the modify function.
 */
export const useQueryParams = <T = Record<string, string | undefined>>(): [
	T,
	SetURLSearchParams
] => {
	const [searchParams, setSearchParams] = useSearchParams()

	const queryParams = useMemo((): Record<string, string | undefined> => {
		return Array.from(searchParams.entries()).reduce(
			(keyValueMap, [key, value]) => ({ ...keyValueMap, [key]: value }),
			{}
		)
	}, [searchParams])

	return [queryParams as T, setSearchParams]
}
