import { memo, ReactNode } from 'react'
import { FilterType } from 'src/_shared/enums/filter'
import { classNames } from 'src/_shared/utils/elements'

interface QuickFilterButtonProps {
	icon: ReactNode
	isActive: boolean
	filterType: FilterType
	label: string
	onClick: () => void
}

const QuickFilterButton = ({
	label,
	icon,
	isActive,
	onClick: handleClick
}: QuickFilterButtonProps): JSX.Element => {
	return (
		<button
			className={classNames(
				'btn h-7 min-w-10 rounded-full bg-opacity-100 px-2.5 shadow-md',
				isActive
					? 'primary text-button-primary-content'
					: 'bg-grayscale-100 text-typography-primary'
			)}
			onClick={handleClick}
		>
			{icon}
			<p className="caption-3-normal">{label}</p>
		</button>
	)
}

const MemoisedQuickFilterButton = memo(QuickFilterButton)

export default MemoisedQuickFilterButton
