import { useCallback, useMemo } from 'react'
import Button from 'src/_shared/components/Button'
import FilterIcon from 'src/_shared/components/_icons/FilterIcon'
import { LocationFilterModalType } from 'src/_shared/enums/filter'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'
import { classNames } from 'src/_shared/utils/elements'

interface LocationFilterButtonProps {
	className?: string
}

const LocationFilterButton = ({ className }: LocationFilterButtonProps): JSX.Element => {
	const { locationFilters } = useLocationFilters()

	const { showModal } = useLocationFiltersModalContext()

	const isFilterActive = useMemo((): boolean => {
		return (
			locationFilters.powerType !== null ||
			locationFilters.cpoEntityCodes !== null ||
			locationFilters.subscriptionStatus !== null ||
			locationFilters.favouritesOnly
		)
	}, [locationFilters])

	const handleFilterButtonClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>): void => {
			event.stopPropagation()
			event.preventDefault()
			showModal(LocationFilterModalType.All)
		},
		[showModal]
	)

	return (
		<Button
			className={classNames('rounded-full', className)}
			variant="none"
			onClick={handleFilterButtonClick}
		>
			<div className="relative">
				<FilterIcon className="h-6 w-6 text-typography-primary" />
				{isFilterActive && (
					<div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-error-300" />
				)}
			</div>
		</Button>
	)
}

export default LocationFilterButton
