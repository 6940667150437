import { ConnectorPowerType, SubscriptionStatus } from 'src/_shared/enums/filter'
import { LocationFilters } from 'src/_shared/types/filter'
import { OmniEvse } from 'src/_shared/types/omni'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { getConnectorPowerType, hasAvailableConnectors } from 'src/_shared/utils/charging'

/**
 * Checks if an EVSE has a connector with the specified power type.
 * @param {OmniEvse} evse The EVSE object to check.
 * @param {ConnectorPowerType|null} powerTypeFilter The power type to filter by. If null, the function returns true.
 * @returns {boolean} A boolean indicating whether the EVSE has a connector with the specified power type.
 */
const hasConnectorWithPowerType = (
	evse: OmniEvse,
	powerTypeFilter: ConnectorPowerType | null
): boolean => {
	if (powerTypeFilter) {
		return (
			!!evse.connectors &&
			evse.connectors.some((connector) => getConnectorPowerType(connector) === powerTypeFilter)
		)
	}
	return true
}

/**
 * Filters EVSEs based on the presence of connectors and an optional power type filter.
 * @param {OmniEvse[]} evses The list of EVSEs to filter.
 * @param {ConnectorPowerType|null} powerTypeFilter The power type filter to apply. If `null`, no power type filtering is applied.
 * @returns {OmniEvse[]} The filtered list of EVSEs that have connectors and match the power type filter.
 */
export const filterEvsesByPowerType = (
	evses: OmniEvse[] = [],
	powerTypeFilter: ConnectorPowerType | null
): OmniEvse[] => {
	const allEvses = evses.filter((evse) => !!evse.connectors && evse.connectors.length > 0)
	const filteredEvsesByPower = allEvses.filter((evse) => {
		return hasConnectorWithPowerType(evse, powerTypeFilter)
	})
	return filteredEvsesByPower
}

/**
 * Filters a location based on subscription status, favourites and distance from current coordinates.
 * Theis logic are decoupled out from the main filter function as this will be used to filter locations returned from CPO BE.
 * @param {OmniLocation} location The location to be filtered.
 * @param {string[]} subscribedCpoEntities A list of subscribed CPO entity codes.
 * @param {string[]} favouriteLocations A list of favourite location UIDs.
 * @param {LocationFilters} locationFilters The filters to apply on the location.
 * @returns {boolean} A boolean indicating whether the location passes the filters.
 */
export const filterLocationsByAppliedFilters = (
	location: OmniLocation,
	subscribedCpoEntities: string[] = [],
	favouriteLocations: string[],
	locationFilters: LocationFilters
): boolean => {
	// 1. Check if the location is filtered by subscription status
	if (locationFilters.subscriptionStatus !== null) {
		const isLocationSubscribed =
			location.entity_code && subscribedCpoEntities.includes(location.entity_code)
				? SubscriptionStatus.Subscribed
				: SubscriptionStatus.NotSubscribed
		if (locationFilters.subscriptionStatus !== isLocationSubscribed) {
			return false
		}
	}
	// 2. Check if the location is filtered by favourites
	if (
		locationFilters.favouritesOnly &&
		location.uid &&
		!favouriteLocations.includes(location.uid)
	) {
		return false
	}
	return true
}

/**
 * Filters a location based on various location filters including
 * power type, CPO entity code, subscription status, and distance.
 * @param {OmniLocation} location The location to be filtered.
 * @param {string[]} subscribedCpoEntities A list of subscribed CPO entity codes.
 * @param {string[]} favouriteLocations A list of favourite location UIDs.
 * @param {LocationFilters} locationFilters The filters to apply on the location.
 * @returns {boolean} A boolean indicating whether the location passes the filters.
 */
export const filterLocationWithLocationFilters = (
	location: OmniLocation,
	subscribedCpoEntities: string[] = [],
	favouriteLocations: string[],
	locationFilters: LocationFilters
): boolean => {
	// 1. Check if the location has connectors based on the current powerTypeFilter
	if (filterEvsesByPowerType(location.evses, locationFilters.powerType).length === 0) {
		return false
	}
	// 2. Check if the location is filtered by CPO
	if (
		locationFilters.cpoEntityCodes !== null &&
		!!location.entity_code &&
		!locationFilters.cpoEntityCodes.includes(location.entity_code)
	) {
		return false
	}
	// 3. Check if the location is within the distance range + favourites + subscription status
	if (
		!filterLocationsByAppliedFilters(
			location,
			subscribedCpoEntities,
			favouriteLocations,
			locationFilters
		)
	) {
		return false
	}
	return true
}

/**
 * Filters the connectors of a given location based on the specified location filters.
 * @param {OmniLocation} location The location to be filtered
 * @param {LocationFilters} locationFilters The filters to apply on the location's EVSEs.
 * @returns {boolean} A boolean indicating whether any of the filtered EVSEs have available connectors.
 */
export const filterConnectorsWithLocationFilters = (
	location: OmniLocation,
	locationFilters: LocationFilters
): boolean => {
	const filteredEvses = filterEvsesByPowerType(location.evses, locationFilters.powerType)
	return filteredEvses.some(
		(evse): boolean => evse.connectors !== undefined && hasAvailableConnectors(evse.connectors)
	)
}
