import { memo, MouseEvent } from 'react'
import Spinner from 'src/_shared/components/Spinner'
import { classNames } from 'src/_shared/utils/elements'

interface IconButtonProps {
	className?: string
	children: JSX.Element
	onClick: (event: MouseEvent) => void
	isLoading?: boolean
}

const IconButton = ({
	className,
	children,
	onClick: handleClick,
	isLoading = false
}: IconButtonProps): JSX.Element => {
	return (
		<button
			onClick={handleClick}
			className={classNames('flex h-10 w-10 items-center justify-center rounded-full', className)}
		>
			{isLoading ? <Spinner className="h-5 w-5" /> : children}
		</button>
	)
}

const MemoisedIconButton = memo(IconButton)

export default MemoisedIconButton
